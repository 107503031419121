import React from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { ChangeAppealStatus } from '../../../../../store/appeals/appeals.actions';
import { useLockBodyScroll } from '../../../../helpers';
import { ActionButton, ConfirmDialog, Title } from './styled';
import { DialogProps, Textarea } from '../../../../components/shared';
import { FlexWithSpacing } from '../../../../typography/flex';
import { AppealId } from '../../../../models/appeal';
import { CreateComment } from '../../../../../store/comments/comments.actions';
import { useActionListener } from '../../../../helpers/actions/action-listener.hook';

type ConfirmChangeStatusModalProps = {
  status: string;
  appealId: AppealId | undefined;
} & Pick<DialogProps, 'onClose'>;
export const ConfirmChangeStatusModal = (props: ConfirmChangeStatusModalProps) => {
  const dispatch = useDispatch();

  const [comment, setComment] = React.useState('');

  const onConfirmClickHandler = React.useCallback(() => {
    if (!props?.appealId) return;
    dispatch(ChangeAppealStatus.init({ id: props.appealId, status: props.status }));
    props?.onClose();
  }, [comment, dispatch, props]);

  useActionListener((action: AnyAction) => {
    if (action.type === ChangeAppealStatus.success.type && props.appealId) {
      dispatch(CreateComment.init({ id: String(props.appealId), text: comment, entity: 'appeal' }));
    }
  });

  const body = React.useMemo(() => {
    return (
      <>
        <Title>Введите комментарий при смене статуса заявки</Title>
        <Textarea value={comment} onChange={e => setComment(e.target.value)} />
        <FlexWithSpacing spacing="15px">
          <ActionButton onClick={onConfirmClickHandler} disabled={!comment}>
            Продолжить
          </ActionButton>
          <ActionButton onClick={props.onClose} mod="secondary">
            Отмена
          </ActionButton>
        </FlexWithSpacing>
      </>
    );
  }, [comment, props.onClose, onConfirmClickHandler]);

  useLockBodyScroll();

  return <ConfirmDialog onClose={props.onClose} body={body} />;
};
